<template>

    <b-overlay :show="loading"
               :variant="variant"
               :opacity="opacity"
               :blur="blur"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-large
               class="loaderPreview"
               rounded="sm">
        <section>

            <b-row v-if="(meta.breadcrumb || meta.pageTitle) && dataLoaded"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="6">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                {{ meta.pageTitle }}
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                       :key="item.text"
                                                       :active="item.active"
                                                       :to="item.to">
                                        {{ item.text }}
                                    </b-breadcrumb-item>
                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- Content Right -->
                <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="6"
                       cols="12">

                </b-col>
            </b-row>

            <b-card v-if="dataLoaded">
                <div class="pb-2">
                    <div class="row d-flex justify-content-between flex-wrap">
                        <b-col lg="8"></b-col>
                        <b-col lg="4" class="text-right">
                            <b-form-group label="Search" v-if="totalRows>0"
                                          label-cols-sm="3"
                                          label-align-sm="right"
                                          label-size="sm"
                                          label-for="SearchInput"
                                          class="mb-0 align-items-center">
                                <b-input-group size="sm">
                                    <b-form-input id="SearchInput"
                                                  v-model="filter"
                                                  type="text"
                                                  placeholder="Type to Search"
                                                   />
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">
                                            Clear
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </div>
                </div>

                <b-table striped
                         hover
                         bordered
                         responsive
                         fixed
                         class="position-relative"
                         :current-page="currentPage"
                         :items="items"
                         :fields="fields"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         :filter="filter"
                         :filter-included-fields="filterOn"
                         @filtered="onFiltered"
                         show-empty>
                    <template #empty="scope">
                        Record doesn't exist
                    </template>
                    <!-- <template #cell(actions)="data">
                        <b-button size="sm"
                                  v-if="data.item.IsSubscribed == false"
                                  v-b-modal.editContact
                                  type="button"
                                  variant="danger"
                                  class="border border-light"
                                  @click="unsubscribe(data.item.Id, data.item.ProjectId)">
                            No
                        </b-button>
                        <b-button size="sm"
                                  v-else
                                  v-b-modal.editContact
                                  type="button"
                                  variant="default"
                                  class="border border-light"
                                  @click="unsubscribe(data.item.Id, data.item.ProjectId)">
                            No
                        </b-button>

                        <b-button size="sm"
                                  v-if="data.item.IsSubscribed"
                                  v-b-modal.projectmodal
                                  type="button"
                                  variant="success"
                                  class="border border-light"
                                  @click="subscribe(data.item.Id, data.item.ProjectId)">
                            Yes
                        </b-button>
                        <b-button size="sm"
                                  v-else
                                  v-b-modal.projectmodal
                                  type="button"
                                  variant="default"
                                  class="border border-light"
                                  @click="subscribe(data.item.Id, data.item.ProjectId)">
                            Yes
                        </b-button>
                    </template> -->
                    <template #cell(date)="data">
                        <span>
                            {{
              data.item.TransDateTime | moment("DD-MMM-YYYY hh:mm:ss A")
                            }}
                        </span>
                    </template>
                </b-table>



                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                    <b-form-group label="Show"
                                  label-cols="4"
                                  label-align="center"
                                  label-size="sm"
                                  label-for="sortBySelect"
                                  class="text-nowrap mb-md-0 mr-1">
                        <b-form-select id="perPageSelect"
                                       v-model="perPage"
                                       size="sm"
                                       @change="gettransactionsdata()"
                                       inline
                                       :options="pageOptions" />
                    </b-form-group>
                    <div v-if="totalRows > perPage">
                        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                            <b-form-group label="Show"
                                          label-cols="4"
                                          label-align="center"
                                          label-size="sm"
                                          label-for="sortBySelect"
                                          class="text-nowrap mb-md-0 mr-1">
                                <b-form-select id="perPageSelect"
                                               v-model="perPage"
                                               size="sm"
                                               @change="gettransactionsdata()"
                                               inline
                                               :options="pageOptions" />
                            </b-form-group>
                            <div>
                                <b-pagination v-model="currentPage"
                                              :total-rows="totalRows"
                                              :per-page="perPage"
                                              first-number
                                              last-number
                                              prev-class="prev-item"
                                              next-class="next-item"
                                              class="mb-0"
                                              @change="loadPage">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </b-card-body>
                    </div>
                </b-card-body>
            </b-card>

        </section>
    </b-overlay>
</template>


<script>
    import BCardCode from "@core/components/b-card-code";

    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardImg,
        BRow,
        BCol,
        BCardText,
        BLink,
        BButton,
        BModal,
        VBModal,
        BFormInput,
        BInputGroupAppend,
        BForm,
        BFormRow,
        BFormSelect,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BFormGroup,
        BTabs,
        BTab,
        BInputGroup,
        BTable,
        BPagination,
        BOverlay,
        BBreadcrumb, BBreadcrumbItem,
    } from "bootstrap-vue";
    import { codeKitchenSink } from "../../table/bs-table/code.js";
    import jwt from "@/auth/jwt/useJwt";
    import { ValidationProvider, ValidationObserver } from "vee-validate";

    import { $themeConfig } from "@themeConfig";
    import moment from "moment";



    export default {
        metaInfo: {
            title: 'Landingly | Orders'
        },
        components: {
            BOverlay,
            BPagination,
            BTable,
            BInputGroupAppend,
            BInputGroup,
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormSelect,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BTabs,
            BTab,
            BBreadcrumb, BBreadcrumbItem,
        },
        data() {
            return {
                variant: "light",
                opacity: 0.85,
                blur: "2px",

                meta: {
                    pageTitle: 'Orders',
                    breadcrumb: [
                        {
                            text: 'Orders',
                            active: true
                        },
                    ],
                },
                dataLoaded: false,
                FunnelName: "",
                Noactive: false,
                Yesactive: true,
                loading: false,
                error: null,
                CurrUser: JSON.parse(localStorage.getItem("User_Name")),
                Id: this.$route.params.id,
                WebAPIURL: $themeConfig.app.apiURL,
                perPage: 20,
                pageOptions: [10, 20, 50, 100],
                totalRows: 1,
                currentPage: 1,
                sortBy: "",
                sortDesc: false,
                sortDirection: "asc",
                filter: null,
                SearchText: null,
                filterOn: [],
                infoModal: {
                    id: "info-modal",
                    title: "",
                    content: "",
                },
                fields: [
                    { key: "TransCustomerEmail", label: "Email", sortable: true },
                    //{ key: "FullName", label: "Name", sortable: true },
                    { key: "ProductName", label: "Product", sortable: true },
                    {
                        key: "date", label: "Date", sortable: true
                    },

                    {
                        key: "TransAmount", label: "Amount($)", sortable: true,
                        thStyle: { width: "125px" },
                    },
                    {
                        key: "PaymentExecStatus", label: "Status", sortable: true,
                        thStyle: { width: "150px" },
                    },


                ],
                Csvfields: {
                    "Contact Name": "FirstName",
                    Email: "Email",
                    "Added Date": "RecCreatedDt",
                    "Is Subscribed?": "IsSubscribed",
                },
                /* eslint-disable global-require */
                items: null,
                /* eslint-disable global-require */
                status: [
                    {
                        1: "Current",
                        2: "Professional",
                        3: "Rejected",
                        4: "Resigned",
                        5: "Applied",
                    },
                    {
                        1: "light-primary",
                        2: "light-success",
                        3: "light-danger",
                        4: "light-warning",
                        5: "light-info",
                    },
                ],
                codeKitchenSink,
            };
        },
        created() {
            var subDomain = jwt.getSubDomain();
            //if (subDomain == null || subDomain == "" || subDomain == 'undefined') {
            //    //this.$router.replace('/setupuseraccount')
            //    this.$router.replace('/login')
            //}
            //else {
            this.gettransactionsdata(1);
            //}
        },
        beforeCreate() {
            
        },
        mounted() {

        },
        destroyed() {

        },
        methods: {
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`;
                this.infoModal.content = JSON.stringify(item, null, 2);
                this.$root.$emit("bv::show::modal", this.infoModal.id, button);
            },
            resetInfoModal() {
                this.infoModal.title = "";
                this.infoModal.content = "";
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                //this.totalRows = filteredItems.length
                //this.currentPage = 1
            },
            clearSearchField() {
                this.SearchText = '';
                this.gettransactionsdata();
            },

            async gettransactionsdata(pagenumber = 1) {
                this.currentPage = pagenumber;
                var self = this;
                var searchvalue = this.SearchText;
                var startValue = (pagenumber - 1) * this.perPage;
                var length = this.perPage;
                let formdata = new FormData();
                formdata.append('SearchValue', searchvalue);
                formdata.append('Index', startValue);
                formdata.append('Count', length);

                this.loading = true;
                const access_token = await jwt.getToken();


                var axios = require("axios");
                axios
                    .post("" + this.WebAPIURL + "/api/Transaction/Transactions",
                        formdata, {
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        self.items = response.data;
                        self.totalRows = response.data.length;
                        //if (response.data.length > 0) {
                        //    this.totalRows = response.data[0].TotalCount;
                        //} else {
                        //    this.totalRows = 0;
                        //}
                        self.loading = false;
                        self.dataLoaded = true;
                    });
            },
            loadPage(pagenumber) {
                this.gettransactionsdata(pagenumber);
            },
            searchtransactiondata(searchvalue) {

            }

        },

    };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

